import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type themeState = {
    dark: boolean;
}

const initialState: themeState = {
    dark: false,
};

const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setTheme(state: themeState, action: PayloadAction<boolean>) {
            state.dark = action.payload
        }
    }
})

export const {setTheme} = themeSlice.actions;
export default themeSlice;