import { useState, useEffect } from "react";
import { useLocation, Outlet, Navigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'


export function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
}

export function PrivateRoute() {
    const location = useLocation()
    const auth = useSelector((state: RootState) => state.auth);
    return auth.account ? <Outlet /> : <Navigate to="/entrar" state={{ from: location }} replace />;
}

export function Url(path: string) {

    if (process.env.DEVELOPMENT_MODE === 'False') {
        return `${process.env.API_URL}${path}`;
    } else {
        return `${process.env.LOCALHOST}${path}`;
    }
}


export function Unauthorized({ status, onClose, message }: { status: boolean; onClose: () => void; message: string }) {


    return (
        <>
            <Dialog open={status} as="div" className="relative z-10 focus:outline-none" onClose={onClose}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-md rounded-xl bg-black/5 dark:bg-white/5 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <DialogTitle as="h3" className="text-base/7 font-medium text-gray-800 dark:text-white">
                                Crea una cuenta
                            </DialogTitle>
                            <p className="mt-2 text-base/6  text-black/80 dark:text-white/50">
                                {message}
                            </p>
                            <div className="mt-4">
                                <Button
                                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                                    onClick={close}
                                >
                                    Registrar
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

interface ReCaptchaProps {
    siteKey: string;
    action: string;
    onVerify: (token: string) => void;
}

export function ReCaptcha({ siteKey, action, onVerify }: ReCaptchaProps) {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
        // Cargar el script de Google reCAPTCHA si no está cargado
        if (!window.grecaptcha) {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
            script.async = true;
            script.onload = () => setIsScriptLoaded(true);
            document.body.appendChild(script);
        } else {
            setIsScriptLoaded(true);
        }

        
        
    }, [siteKey]);

    useEffect(() => {
        if (isScriptLoaded && window.grecaptcha) {
            // Ejecutar reCAPTCHA para generar el token
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(siteKey, { action }).then((token: string) => {
                    onVerify(token);
                });
            });
        }
    }, [isScriptLoaded, siteKey, action, onVerify]);

    return null;
}