import { Fragment, useState } from 'react'
import { Link, NavLink } from 'react-router'
import { Dialog, DialogPanel, PopoverGroup, Transition, TransitionChild } from '@headlessui/react'
import { SunIcon, MoonIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
//import { ReactSVG } from 'react-svg'
import { useSelector, useDispatch } from "react-redux";
import authSlice from "../store/slices/auth";
import { setTheme } from "../store/slices/theme";
import { RootState } from "../store";
import Refresh from "../auth/Refresh";
// import background from '/static/background.jpg'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const navigation = {
    pages: [
        { name: 'Soluciones', href: '/soluciones' },
        { name: 'Nosotros', href: '/nosotros' },
        // { name: 'Blog', href: '/blog' },
        { name: 'Contacto', href: '/contacto' },
    ],
}

export default function Navbar() {

    const auth = useSelector((state: RootState) => state.auth);
    const theme = useSelector((state: RootState) => state.theme.dark);
    const dispatch = useDispatch();

    const handleTheme = () => {
        dispatch(setTheme(!theme));
    }

    const handleLogout = () => {
        dispatch(authSlice.actions.setLogout())
    };
    
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className=''>
                {/* Mobile menu */}
                <Transition show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
                        <TransitionChild
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </TransitionChild>

                        <div className="fixed inset-0 z-40 flex">
                            <TransitionChild
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <DialogPanel
                                    className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                                    <div className="flex px-4 pt-5 pb-2">
                                        <button
                                            type="button"
                                            className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Cerrar menú</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    {/* Links */}


                                    <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                                        {navigation.pages.map((page) => (
                                            <div key={page.name} className="flow-root">
                                                <NavLink
                                                    to={page.href}
                                                    state={{ from: location.pathname }}
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                    className={({ isActive }) =>
                                                        classNames(
                                                            isActive ? 'text-dandelion-900' : 'text-gray-900',
                                                            '-m-2 block p-2 font-semibold'
                                                        )
                                                    }
                                                    end
                                                >
                                                    {page.name}
                                                </NavLink>
                                            </div>
                                        ))}
                                    </div>
                                    {auth.account ? (
                                        <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                                            <div className="flow-root">
                                                <Link to="/a/dashboard"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                    className="-m-2 block p-2 font-semibold text-gray-900">
                                                    Dashboard
                                                </Link>
                                            </div>
                                            <div className="flow-root">
                                                <button onClick={() => {
                                                    handleLogout()
                                                    setOpen(false)
                                                }}
                                                    className="-m-2 block p-2 font-semibold text-gray-900">
                                                    Cerrar sesión
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                                            <div className="flow-root">
                                                <Link to="/entrar"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                    className="-m-2 block p-2 font-semibold text-gray-900">
                                                    Entrar
                                                </Link>
                                            </div>
                                           
                                        </div>
                                    )}

                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </Dialog>
                </Transition>
                {/* Web menu */}
                <header className="fixed top-0 left-0 right-0 z-40 backdrop-blur bg-white/80 dark:bg-[#0f172a]/80">
                    {/* <p className="flex h-10 items-center justify-center bg-dandelion-600 px-4 text-sm font-semibold text-white sm:px-6 lg:px-8">
                        Envío gratis en pedidos mayores a $100
                    </p> */}
                    <nav aria-label="Top" className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                        <div className="border-b border-gray-200 dark:border-gray-800">
                            <div className="flex h-12 items-center">
                                <button
                                    type="button"
                                    className="rounded-md p-2 text-gray-400 lg:hidden"
                                    onClick={() => setOpen(true)}
                                >
                                    <span className="sr-only">Open menu</span>
                                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                {/* Logo */}
                                <div className="ml-auto flex lg:ml-0">
                                    <Link to="/">
                                        <span className="sr-only">Volta</span>
                                        <svg className='h-8 w-auto fill-gray-800 dark:fill-white' viewBox="0 0 55.12 14.92" xmlns="http://www.w3.org/2000/svg">
                                            <g
                                            >
                                                <path
                                                    d="M 7.46,0 C 5.62,0.01 3.84,0.68 2.46,1.9 L 2.29,2.08 C 1.68,2.63 1.17,3.28 0.78,4 L 0.66,4.27 C 0.6,4.39 0.55,4.5 0.5,4.62 c -0.67,1.69 -0.67,3.58 0,5.27 0.1,0.18 0.14,0.43 0.32,0.54 H 0.93 C 3.24,9.94 4.79,7.77 4.52,5.43 L 4.46,5.11 V 5 C 4.38,4.32 4.46,3.64 4.69,3 L 4.78,2.8 C 4.78,2.71 4.85,2.62 4.89,2.53 5.51,1.29 6.65,0.38 8,0.07 V 0 Z m 0.07,11.07 0.22,-0.2 v 0 l 0.17,-0.14 c 0.83,-0.64 1.85,-1 2.9,-1 v 0 c 1.28,0 2.5,0.52 3.39,1.43 v 0.14 l -0.12,0.2 c -1.13,1.76 -2.94,2.97 -5,3.33 L 8.68,14.89 H 8.41 C 7.72,14.96 7.02,14.92 6.34,14.8 H 6.18 C 3.96,14.36 2.07,12.93 1.03,10.92 v -0.06 c 0,0 0,-0.07 0.07,0 v 0 c 1.03,1.01 2.47,1.5 3.9,1.32 L 5.34,12.12 H 5.51 C 6.19,11.97 6.83,11.67 7.38,11.24 L 7.55,11.1 M 11.96,5.92 C 11.81,5.89 11.67,5.85 11.53,5.8 H 11.39 C 9.02,5.01 7.75,2.45 8.54,0.08 h 0.17 c 4.03,0.31 7.04,3.82 6.73,7.85 -0.07,0.93 -0.32,1.84 -0.73,2.67 -0.05,0.09 -0.1,0.19 -0.16,0.28 -0.06,0.09 0,0 0,0 0,-0.07 0,-0.15 0,-0.22 C 14.72,9.37 14.32,8.08 13.47,7.1 v 0 c -0.54,-0.61 -1.23,-1.07 -2,-1.33"
                                                />
                                                <path
                                                    d="m 25.4,10.75 3.82,-8.82 h 1.12 l -4.43,10 h -1.12 l -4.46,-10 h 1.2 z"
                                                />
                                                <path
                                                    d="m 30.16,8.29 c -0.04,-0.99 0.32,-1.96 1,-2.69 0.82,-0.82 1.95,-1.25 3.11,-1.18 1.17,-0.07 2.31,0.36 3.14,1.18 0.68,0.73 1.04,1.7 1,2.69 0.04,0.99 -0.32,1.96 -1,2.68 -0.83,0.82 -1.96,1.24 -3.12,1.18 C 33.14,12.23 32.01,11.81 31.18,11 30.48,10.27 30.12,9.29 30.16,8.29 Z m 1,0 c -0.02,0.81 0.28,1.59 0.84,2.17 0.61,0.57 1.43,0.87 2.27,0.82 0.83,0.05 1.65,-0.24 2.26,-0.82 C 37.09,9.88 37.39,9.1 37.36,8.29 37.39,7.48 37.09,6.7 36.53,6.12 35.22,5.02 33.31,5.02 32,6.12 31.44,6.7 31.15,7.49 31.19,8.29 Z"
                                                />
                                                <path
                                                    d="M 39.73,11.93 V 1.85 h 1 v 10.08 z"
                                                />
                                                <path
                                                    d="m 43.05,4.65 v -2.3 h 1 v 2.3 h 2 v 0.78 h -2 v 4.83 c -0.02,0.25 0.05,0.51 0.21,0.71 0.24,0.17 0.53,0.24 0.82,0.21 0.34,0 0.67,-0.02 1,-0.09 V 12 c -0.37,0.05 -0.75,0.08 -1.13,0.08 -0.57,0.07 -1.14,-0.1 -1.58,-0.46 -0.29,-0.39 -0.43,-0.87 -0.38,-1.35 V 5.43 H 41.47 V 4.65 Z"
                                                />
                                                <path
                                                    d="m 48.16,6.89 h -0.95 c 0.02,-0.69 0.33,-1.35 0.85,-1.81 0.78,-0.5 1.7,-0.73 2.62,-0.66 0.89,-0.06 1.79,0.14 2.57,0.58 0.5,0.36 0.79,0.96 0.75,1.58 v 3.81 c 0,0.54 0.19,0.81 0.58,0.81 h 0.54 v 0.67 c -0.29,0.07 -0.58,0.1 -0.87,0.11 -0.37,0.05 -0.74,-0.12 -0.95,-0.43 -0.12,-0.27 -0.18,-0.56 -0.17,-0.86 v 0 c -0.8,1.04 -2.09,1.6 -3.4,1.46 -0.66,0.02 -1.3,-0.15 -1.87,-0.48 -0.63,-0.33 -1.01,-0.99 -1,-1.7 -0.04,-0.82 0.46,-1.57 1.24,-1.85 0.78,-0.28 1.6,-0.43 2.43,-0.44 0.71,0 1.41,-0.09 2.1,-0.25 0.29,-0.11 0.46,-0.4 0.43,-0.71 C 53.1,6.27 52.88,5.85 52.5,5.61 51.92,5.36 51.28,5.25 50.65,5.29 50.01,5.25 49.38,5.37 48.81,5.66 48.4,5.93 48.15,6.4 48.16,6.89 Z m 4.9,2.22 V 8 c -0.31,0.17 -0.65,0.27 -1,0.3 -0.31,0 -1,0.13 -2.23,0.24 -1.23,0.11 -1.9,0.57 -1.9,1.35 0,0.98 0.7,1.47 2.1,1.47 0.71,0 1.4,-0.19 2,-0.56 0.62,-0.34 1.01,-0.98 1.03,-1.69 z"
                                                />
                                            </g>
                                        </svg>
                                    </Link>
                                </div>

                                {/* Flyout menus */}
                                <PopoverGroup className="hidden lg:ml-auto lg:block lg:self-stretch">
                                    <div className="flex h-full space-x-8">

                                        {navigation.pages.map((page) => (
                                            <NavLink
                                                key={page.name}
                                                to={page.href}
                                                state={{ from: location.pathname }}
                                                className={({ isActive }) =>
                                                    classNames(
                                                        isActive ? 'font-medium text-dandelion-600 dark:text-dandelion-500' : 'dark:text-dandelion-100 hover:text-dandelion-600 dark:hover:text-dandelion-400',
                                                        'flex items-center text-[13px] transition-colors duration-200 ease-out'
                                                    )
                                                }
                                                end
                                            >
                                                {page.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </PopoverGroup>
                                <div className="ml-auto flex items-center text-[13px] dark:text-dandelion-100">
                                    {auth.account ? (
                                        <div
                                            className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                            <Link to="/a/dashboard"
                                                className="hover:text-dandelion-600 dark:hover:text-dandelion-500 transition-colors duration-200 ease-out">
                                                Dashboard
                                            </Link>
                                            <Link to="/contacto"
                                                className="text-black font-medium rounded-md bg-dandelion-500 px-6 py-1.5 shadow-sm hover:bg-gradient-to-r hover:from-pink-500 hover:to-violet-500 transition-colors duration-200 ease-out">
                                                Solicitar información
                                            </Link>
                                            <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                                            <button onClick={() => handleLogout()}
                                                className="hover:text-dandelion-400 transition-colors duration-200 ease-out">
                                                Cerrar sesión
                                            </button>
                                        </div>
                                    ) : (
                                        <div
                                            className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">

                                            <Link to="/contacto"
                                                className="text-black font-medium rounded-md bg-dandelion px-6 py-1.5 shadow-sm hover:text-white hover:bg-gradient-to-r hover:from-pink-500 hover:to-violet-500 transition-colors duration-200 ease-out">
                                                Solicitar información
                                            </Link>
                                            <span className="h-6 w-px bg-gray-300 dark:bg-gray-200" aria-hidden="true" />
                                            <Link to="/entrar"
                                                className="hover:text-dandelion-600 dark:hover:text-dandelion-400 transition-colors duration-200 ease-out">
                                                Entrar
                                            </Link>
                                        </div>
                                    )}
                                    {/* Search */}
                                    <div className="flex lg:ml-6">
                                        <button onClick={handleTheme}
                                            className="p-2 hover:text-dandelion-600 dark:hover:text-dandelion-400 transition-colors duration-200 ease-out">
                                            <span className="sr-only">Dark Mode</span>
                                            {theme ?
                                                (
                                                    <MoonIcon className="h-5 w-5" aria-hidden="true" />
                                                ) : (
                                                    <SunIcon className="h-5 w-5" aria-hidden="true" />
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
            <Refresh />
        </>
    )
}
