import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import authSlice from "../store/slices/auth";
import volta from '/static/Volta.mp4'
import { Link } from "react-router";
import { ShieldCheckIcon, BoltIcon, LightBulbIcon, Battery100Icon, CheckCircleIcon } from '@heroicons/react/24/outline'
import Slider from "react-slick";
import sigenergy from '/static/sigenergy.webp'
import kliux from '/static/kliux.webp'
import panel from '/static/panel.webp'
import sigenergy_02 from '/static/sigenergy_02.webp'
import poster from '/static/poster.webp'
import pnp from '/static/pnp.webp'
import sigenergy_light from '/static/sigenergy_light.webp'
import sigenergy_dark from '/static/sigenergy_dark.webp'
import aiko_light from '/static/aiko_light.webp'
import aiko_dark from '/static/aiko_dark.webp'
import solarlog from '/static/solarlog.webp'
import multiassets from '/static/multiassets.webp'

export default function Landing() {

    return (
        <>
            <Hero />
            <div className='px-4 sm:px-6 lg:px-8'>
                <Solutions />
                <Benefits />
                <Brands />
                <Videos />
                <Associated />
                <div className="mt-6 bg-gradient-to-r from-transparent via-dandelion-50 dark:via-transparent to-transparent">
                    <CallToAction />
                </div>
                <DefaultFooter />
            </div>
        </>
    )
}

export function Hero() {
    const [videoLoaded, setVideoLoaded] = useState(false);


    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    useEffect(() => {

        if ('requestIdleCallback' in window) {
            requestIdleCallback(handleVideoLoad);
        } else {

            handleVideoLoad();
        }
    }, []);

    return (
        <div className="h-screen -top-16 relative flex justify-center items-center">
            {videoLoaded && (
                <video
                    className="absolute top-0 left-0 w-full h-full z-10 object-cover"
                    autoPlay
                    muted
                    loop
                    playsInline
                    controls={false}
                    poster={poster}
                    preload="none"
                >
                    <source src={volta} type="video/mp4" />
                </video>
            )}

            {/* Capa de gradiente */}
            <div
                className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-gradient-to-r from-black/40 to-transparent"
            />

            {/* Contenido de texto */}
            <div className="max-w-6xl mx-auto px-4 mt-16 text-center relative z-20 w-full">
                <div className="mb-6 flex justify-center">
                    <div
                        className="relative drop-shadow-lg bg-black/40 rounded-full px-3 py-1 text-xs leading-6 text-gray-300 ring-1 ring-dandelion-600/40 hover:ring-dandelion-600/20 sm:text-sm"
                    >
                        Recibe una evaluación sin costo.{' '}
                        <Link to="contacto" className="font-semibold text-dandelion">
                            <span className="absolute inset-0" aria-hidden="true" />
                            Solicitar información <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </div>
                <h1 className="mb-6 md:mb-10 font-bold tracking-tight text-dandelion drop-shadow-lg text-5xl sm:text-6xl md:text-8xl lg:text-10xl">
                    Almacenamiento y generación de energía eléctrica.
                </h1>
                <p className="mt-6 italic text-3xl leading-8 text-white drop-shadow-lg sm:text-4xl lg:text-5xl">
                    Energía sin limitaciones.
                </p>
            </div>
        </div>
    );
}

export function Solutions() {

    const features = [
        {
            name: 'El aliado de las renovables',
            description:
                'Gracias al progreso tecnológico, hoy es posible acumular energía para que esté disponible las 24 horas del día. Todo gracias a los sistemas de almacenamiento de energía.',
            icon: BoltIcon,
        },
        {
            name: 'Disponibilidad y rentabilidad',
            description:
                'La producción a gran escala de sistemas de baterías permite que el almacenamiento se afiance más rápidamente, garantizando un rendimiento cada vez mayor.',
            icon: LightBulbIcon,
        },
        {
            name: 'Transición energética',
            description:
                'Gracias a los sistemas de almacenamiento, las energías renovables pueden conseguir un nuevo impulso, haciendo que el proceso de transición energética sea aún más rápido.',
            icon: Battery100Icon,
        },
        {
            name: 'Confiabilidad',
            description:
                'El almacenamiento permite ofrecer servicios para la seguridad del sistema eléctrico (reserva estática, regulación de la frecuencia, voltaje y restablecimiento).',
            icon: ShieldCheckIcon,
        },

    ]


    return (
        <>
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-lg font-semibold text-dandelion-700 dark:text-dandelion">Soluciones</h2>
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">Instalaciones
                        personalizadas llave en mano.</p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-400 lg:mx-auto">Genera y almacena tu propia energía
                        para
                        utilizarla en el momento que la necesites.</p>
                </div>
                <div className="mt-10">
                    <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <div
                                        className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-dandelion text-white">
                                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-400">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </>
    )
}

export function Benefits() {

    const features = [
        {
            name: 'Experiencia',
            description: 'Nuestro equipo directivo cuenta con un profundo conocimiento del mercado eléctrico.'
        },
        { name: 'Talento', description: 'El personal operativo es certificado por los fabricantes de equipos.' },
        { name: 'Cobertura', description: 'Tenemos una red nacional de distribuidores y comercializadores.' },
        {
            name: 'Respaldo',
            description: 'Contamos con el apoyo de integradores internacionales para dimensionamiento y puesta en marcha de sistemas de almacenamiento y generación.'
        },
        { name: 'Legal', description: 'Nuestras instalaciones no requieren autorización gubernamental.' },
        {
            name: 'Fiscal',
            description: 'Instalar nuestros equipos te hacen acreedor a diversos beneficios fiscales a nivel local y federal.'
        },
    ]

    return (
        <>
            <div className="mt-8">
                <div className="text-center">
                    <h2 className="text-lg font-semibold text-dandelion-700 dark:text-dandelion">
                        Beneficios
                    </h2>
                    <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
                        ¿Por qué elegir <p className="inline italic">Volta</p>?
                    </h2>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-400 lg:mx-auto">
                        Somos una empresa mexicana líder con más de 20 distribuciones en materia de almacenamiento,
                        generación y electrónica de potencia.
                    </p>
                </div>
                <div
                    className="mx-auto grid max-w-5xl grid-cols-1 gap-x-8 pt-6 px-4 sm:px-6 sm:pt-8 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                    <div>
                        <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                            {features.map((feature) => (
                                <div key={feature.name} className="border-t border-gray-200 pt-4">
                                    <dt className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        <CheckCircleIcon className="h-6 w-6 inline text-dandelion"
                                            aria-hidden="true" />{' '}
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500 dark:text-gray-400">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                    <div className="mt-8 lg:mt-0 grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
                        <img
                            src={sigenergy}
                            alt=""
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                        <img
                            src={kliux}
                            alt=""
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                        <img
                            src={panel}
                            alt=""
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                        <img
                            src={sigenergy_02}
                            alt=""
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export function Brands() {

    const theme = useSelector((state: RootState) => state.theme.dark);



    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        pauseOnHover: true,

    };

    return (
        <>
            <div className="max-w-5xl mx-auto px-6">
                <div className="mt-10 text-center">
                    <h2 className="text-lg font-semibold text-dandelion-700 dark:text-dandelion">Nuestras marcas</h2>
                    <Slider {...settings} className='py-6'>
                        <img className="object-contain h-28 px-2" src={pnp} alt="Plug & Play Energy" />
                        {theme ? <img className="object-contain h-28 px-2" src={sigenergy_dark} alt="Sigenergy" /> : <img className="object-contain h-28 px-2" src={sigenergy_light} alt="Sigenergy" />}
                        {theme ? <img className="object-contain h-28 px-2" src={aiko_dark} alt="Aiko" /> : <img className="object-contain h-28 px-2" src={aiko_light} alt="Aiko" />}
                        <img className="object-contain h-28 px-2" src={solarlog} alt="Solar-Log" />
                    </Slider>
                </div>
            </div>
        </>
    )
}

export function Videos() {
    const [isVideoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
        <div className="mt-8">
            <div className="text-center">
                <h2 className="text-lg font-semibold text-dandelion-700 dark:text-dandelion">
                    Multimedia
                </h2>
                <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight  text-gray-900 dark:text-gray-100 sm:text-4xl">
                    <p className="inline italic">Volta</p> en los medios.
                </h2>
                <div className="max-w-2xl mx-auto py-4">
                    <div className="aspect-w-16 aspect-h-9">
                        {!isVideoLoaded ? (
                            <div
                                style={{
                                    backgroundImage: `url(https://img.youtube.com/vi/RMCTfCozKTc/maxresdefault.jpg)`,
                                    backgroundSize: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer'
                                }}
                                onClick={handleVideoLoad}
                            >
                                <div className="flex justify-center items-center w-full h-full bg-black opacity-50">
                                    <svg className="w-12 h-12 text-white" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19V6m7 7l-7-7-7 7" />
                                    </svg>
                                </div>
                            </div>
                        ) : (
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/RMCTfCozKTc?start=14"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media"
                                allowFullScreen
                                loading="lazy"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Associated() {


    return (
        <>
            <div className="max-w-5xl mx-auto">
                <div className="mt-8 text-center">
                    <h2 className="text-lg font-semibold text-dandelion-700 dark:text-dandelion">Empresa asociada de:</h2>
                    <img className="py-6 object-contain h-48 mx-auto" src={multiassets} alt="Multiassets" />
                </div>
            </div>
        </>
    )
}

export function CallToAction() {

    return (
        <>
            <div>
                <div
                    className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block dark:text-gray-100">¿Listo para ser parte de la solución?</span>
                        <span className="block text-dandelion-500 dark:text-dandelion">Contáctanos ahora mismo.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <Link
                                to="contacto"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-dandelion-700 px-5 py-3 text-base font-medium text-white hover:bg-dandelion-600"
                            >
                                Contacto
                            </Link>
                        </div>
                        <div className="ml-3 inline-flex rounded-md shadow">
                            <Link
                                to="soluciones"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-dandelion-700 hover:bg-dandelion-50"
                            >
                                Saber más
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function DefaultFooter() {

    const navigation = [
        { name: 'Soluciones', href: '/soluciones' },
        { name: 'Nosotros', href: '/nosotros' },
        // { name: 'Blog', href: '/blog' },
        { name: 'Contacto', href: '/contacto' },
        { name: 'Política de Privacidad', href: 'politica-de-privacidad' }
    ]

    const auth = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(authSlice.actions.setLogout())

    };

    return (
        <>
            <footer className="p-4  border-t border-gray-200 dark:border-gray-800 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <div className="sm:flex mt-2 items-center sm:justify-between">
                        <Link to={'/'} className="flex items-center mb-4 sm:mb-0">
                            <svg className='h-8 w-auto fill-gray-800 dark:fill-white' viewBox="0 0 55.12 14.92" xmlns="http://www.w3.org/2000/svg">
                                <g
                                >
                                    <path
                                        d="M 7.46,0 C 5.62,0.01 3.84,0.68 2.46,1.9 L 2.29,2.08 C 1.68,2.63 1.17,3.28 0.78,4 L 0.66,4.27 C 0.6,4.39 0.55,4.5 0.5,4.62 c -0.67,1.69 -0.67,3.58 0,5.27 0.1,0.18 0.14,0.43 0.32,0.54 H 0.93 C 3.24,9.94 4.79,7.77 4.52,5.43 L 4.46,5.11 V 5 C 4.38,4.32 4.46,3.64 4.69,3 L 4.78,2.8 C 4.78,2.71 4.85,2.62 4.89,2.53 5.51,1.29 6.65,0.38 8,0.07 V 0 Z m 0.07,11.07 0.22,-0.2 v 0 l 0.17,-0.14 c 0.83,-0.64 1.85,-1 2.9,-1 v 0 c 1.28,0 2.5,0.52 3.39,1.43 v 0.14 l -0.12,0.2 c -1.13,1.76 -2.94,2.97 -5,3.33 L 8.68,14.89 H 8.41 C 7.72,14.96 7.02,14.92 6.34,14.8 H 6.18 C 3.96,14.36 2.07,12.93 1.03,10.92 v -0.06 c 0,0 0,-0.07 0.07,0 v 0 c 1.03,1.01 2.47,1.5 3.9,1.32 L 5.34,12.12 H 5.51 C 6.19,11.97 6.83,11.67 7.38,11.24 L 7.55,11.1 M 11.96,5.92 C 11.81,5.89 11.67,5.85 11.53,5.8 H 11.39 C 9.02,5.01 7.75,2.45 8.54,0.08 h 0.17 c 4.03,0.31 7.04,3.82 6.73,7.85 -0.07,0.93 -0.32,1.84 -0.73,2.67 -0.05,0.09 -0.1,0.19 -0.16,0.28 -0.06,0.09 0,0 0,0 0,-0.07 0,-0.15 0,-0.22 C 14.72,9.37 14.32,8.08 13.47,7.1 v 0 c -0.54,-0.61 -1.23,-1.07 -2,-1.33"
                                    />
                                    <path
                                        d="m 25.4,10.75 3.82,-8.82 h 1.12 l -4.43,10 h -1.12 l -4.46,-10 h 1.2 z"
                                    />
                                    <path
                                        d="m 30.16,8.29 c -0.04,-0.99 0.32,-1.96 1,-2.69 0.82,-0.82 1.95,-1.25 3.11,-1.18 1.17,-0.07 2.31,0.36 3.14,1.18 0.68,0.73 1.04,1.7 1,2.69 0.04,0.99 -0.32,1.96 -1,2.68 -0.83,0.82 -1.96,1.24 -3.12,1.18 C 33.14,12.23 32.01,11.81 31.18,11 30.48,10.27 30.12,9.29 30.16,8.29 Z m 1,0 c -0.02,0.81 0.28,1.59 0.84,2.17 0.61,0.57 1.43,0.87 2.27,0.82 0.83,0.05 1.65,-0.24 2.26,-0.82 C 37.09,9.88 37.39,9.1 37.36,8.29 37.39,7.48 37.09,6.7 36.53,6.12 35.22,5.02 33.31,5.02 32,6.12 31.44,6.7 31.15,7.49 31.19,8.29 Z"
                                    />
                                    <path
                                        d="M 39.73,11.93 V 1.85 h 1 v 10.08 z"
                                    />
                                    <path
                                        d="m 43.05,4.65 v -2.3 h 1 v 2.3 h 2 v 0.78 h -2 v 4.83 c -0.02,0.25 0.05,0.51 0.21,0.71 0.24,0.17 0.53,0.24 0.82,0.21 0.34,0 0.67,-0.02 1,-0.09 V 12 c -0.37,0.05 -0.75,0.08 -1.13,0.08 -0.57,0.07 -1.14,-0.1 -1.58,-0.46 -0.29,-0.39 -0.43,-0.87 -0.38,-1.35 V 5.43 H 41.47 V 4.65 Z"
                                    />
                                    <path
                                        d="m 48.16,6.89 h -0.95 c 0.02,-0.69 0.33,-1.35 0.85,-1.81 0.78,-0.5 1.7,-0.73 2.62,-0.66 0.89,-0.06 1.79,0.14 2.57,0.58 0.5,0.36 0.79,0.96 0.75,1.58 v 3.81 c 0,0.54 0.19,0.81 0.58,0.81 h 0.54 v 0.67 c -0.29,0.07 -0.58,0.1 -0.87,0.11 -0.37,0.05 -0.74,-0.12 -0.95,-0.43 -0.12,-0.27 -0.18,-0.56 -0.17,-0.86 v 0 c -0.8,1.04 -2.09,1.6 -3.4,1.46 -0.66,0.02 -1.3,-0.15 -1.87,-0.48 -0.63,-0.33 -1.01,-0.99 -1,-1.7 -0.04,-0.82 0.46,-1.57 1.24,-1.85 0.78,-0.28 1.6,-0.43 2.43,-0.44 0.71,0 1.41,-0.09 2.1,-0.25 0.29,-0.11 0.46,-0.4 0.43,-0.71 C 53.1,6.27 52.88,5.85 52.5,5.61 51.92,5.36 51.28,5.25 50.65,5.29 50.01,5.25 49.38,5.37 48.81,5.66 48.4,5.93 48.15,6.4 48.16,6.89 Z m 4.9,2.22 V 8 c -0.31,0.17 -0.65,0.27 -1,0.3 -0.31,0 -1,0.13 -2.23,0.24 -1.23,0.11 -1.9,0.57 -1.9,1.35 0,0.98 0.7,1.47 2.1,1.47 0.71,0 1.4,-0.19 2,-0.56 0.62,-0.34 1.01,-0.98 1.03,-1.69 z"
                                    />
                                </g>
                            </svg>
                        </Link>
                        <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 dark:text-gray-400 sm:mb-0">
                            {navigation.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.href} className="mr-4 hover:underline md:mr-6 ">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                            {auth.account ? (
                                <button onClick={() => handleLogout()}
                                    className="hover:text-dandelion-400 transition-colors duration-200 ease-out">
                                    Cerrar sesión
                                </button>
                            ) : (
                                <li>
                                    <Link to="/entrar" className="font-bold tracking-tight hover:underline">Entrar</Link>
                                </li>

                            )}
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 dark:border-gray-800 sm:mx-auto" />
                    <div className="mb-4">
                        <div className="block text-sm text-gray-500 text-center">© 2021-2025 <Link
                            to="/" className="hover:underline font-medium">Volta ESS MX, SAPI de C.V.</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}