import { useEffect } from 'react'
import {
    Outlet,
    useLocation
} from "react-router";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import sessionSlice from "./store/slices/session";
import Navbar from "./navbar/Navbar";
import { ToastContainer } from "react-toastify";
import { ScrollToTop } from './utils/Components';
import favicon from '/static/favicon.ico'

export default function App() {

    const location = useLocation()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sessionSlice.actions.setSessionLocation({ location: location.pathname }));
    }, [location, dispatch]);

    const route = useSelector((state: any) => state.session.location);
    const routeString = route?.replace(/\//g, " | ").toString();

    /* function toTitleCase(str: string) {
        return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    } */
    function toTitleCase(str: string) {
        return str
            .toLowerCase()
            .replace(/-/g, " ") // Replace hyphens with spaces
            .replace(/\b(\w)/g, s => s.toUpperCase()); // Capitalize first letters
    }

    const routeLocation = routeString ? toTitleCase(routeString) : null;

    const theme = useSelector((state: RootState) => state.theme.dark);

    useEffect(() => {
        document.documentElement.classList.toggle('dark', theme);
    }, [theme]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
                    <link rel="shortcut icon" href={favicon} />
                    <title>{`Volta Energy ${(routeLocation == " | ") ? " | Almacenamiento y Generación de Energía Eléctrica" : routeLocation}`}</title>
                </Helmet>
            </HelmetProvider>

            <div className="min-h-full">
                <ScrollToTop />
                <div className="pt-12 bg-transparent dark:bg-[#0f172a]">
                    <Navbar />
                    <div className="flex flex-col h-screen">
                        <div className="flex-grow bg-transparent dark:bg-[#0f172a]">
                            <Outlet />
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
