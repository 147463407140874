import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/auth";
import sessionSlice from "./slices/session";
import customerSlice from "./slices/customer";
import themeSlice from "./slices/theme";

const appReducer = combineReducers({
    auth: authSlice.reducer,
    session: sessionSlice.reducer,
    customer: customerSlice.reducer,
    theme: themeSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
/*     if (action.type === 'auth/setLogout') {
        state = undefined;
    } */
    return appReducer(state, action)
}

const persistedReducer = persistReducer(
    {
        key: "root",
        version: 1,
        storage
    },
    rootReducer
);

const store = configureStore(
    {
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                }
            })
    })


export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;

export default store;