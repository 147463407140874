import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../../types";

type State = {
    token: string | null;
    refreshToken: string | null;
    account: AccountResponse | null;
    extend: boolean | false;
};

const initialState: State = { token: null, refreshToken: null, account: null, extend: false };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthTokens(
            state: State,
            action: PayloadAction<{ token: string; refreshToken: string }>
        ) {
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
        },
        setAccount(state: State, action: PayloadAction<AccountResponse>) {
            state.account = action.payload;
        },
        setLogout(state: State) {
            state.account = null;
            state.refreshToken = null;
            state.token = null;
            state.extend = false;
            sessionStorage.removeItem('refresh');

        },
        setExtend(state: State, action: PayloadAction<{ extend: boolean }>) {
            state.extend = action.payload.extend;
        }
    },
});

export default authSlice;