import React, { Suspense } from "react";
import { Navigate } from "react-router";
import { PrivateRoute } from "./utils/Components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import App from "./App";
import Landing from "./public/Landing";

// Lazy load components

const Solutions = React.lazy(() => import("./public/Solutions"));
const Login = React.lazy(() => import("./auth/Login"));
const AboutUs = React.lazy(() => import("./public/AboutUs"));
const Contact = React.lazy(() => import("./public/Contact"));
const Dashboard = React.lazy(() => import("./private/Dashboard"));
const PrivacyPolicy = React.lazy(() => import("./public/PrivacyPolicy"))

export const routes = [
    {
        path: "/",
        element: (
            <App />
        ),
        children: [
            {
                index: true,
                element: (
                    <Landing />
                ),
            },
            {
                path: "soluciones",
                element: (
                    <Suspense fallback={
                        <div className="flex justify-center items-center h-screen">
                            <div className="w-16 h-16 border-t-4 border-dandelion-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }>
                        <Solutions />
                    </Suspense>
                ),
            },
            {
                path: "nosotros",
                element: (
                    <Suspense fallback={
                        <div className="flex justify-center items-center h-screen">
                            <div className="w-16 h-16 border-t-4 border-dandelion-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }>
                        <AboutUs />
                    </Suspense>
                ),
            },
            {
                path: "contacto",
                element: (
                    <Suspense fallback={
                        <div className="flex justify-center items-center h-screen">
                            <div className="w-16 h-16 border-t-4 border-dandelion-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }>
                        <Contact />
                    </Suspense>
                ),
            },
            {
                path: "politica-de-privacidad",
                element: (
                    <Suspense fallback={
                        <div className="flex justify-center items-center h-screen">
                            <div className="w-16 h-16 border-t-4 border-dandelion-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }>
                        <PrivacyPolicy />
                    </Suspense>
                ),
            },
            {
                path: "entrar",
                element: (
                    <Suspense fallback={
                        <div className="flex justify-center items-center h-screen">
                            <div className="w-16 h-16 border-t-4 border-dandelion-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }>
                        <Login />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: "a",
        element: <PrivateRoute />,
        children: [
            {
                index: true,
                element: <Navigate to="dashboard" replace />,
            },
            {
                path: "dashboard",
                element: (
                    <App />
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <>
                                <HelmetProvider>
                                    <Helmet>
                                        <title>Volta | Dashboard</title>
                                    </Helmet>
                                </HelmetProvider>
                                <Suspense fallback={
                                    <div className="flex justify-center items-center h-screen">
                                        <div className="w-16 h-16 border-t-4 border-dandelion-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }>
                                    <Dashboard />
                                </Suspense>
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="/" replace />,
    },
];
